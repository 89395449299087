const Products = [
  {
    id: 1,
    Productname: "Orgoroz-AS 10",
    Content: "Rosuvastatin Calcium 10mg + Aspirin 75mg Capsule",
    path: "/orgomed products/Orgoroz AS 10.jpg",
    mrp : 128,
  },

  {
    id: 2,
    Productname: "Orgotel-AM",
    Content: "Telmisartan 40mg + Amlodipine Besilate 5mg Tablet",
    path: "/orgomed products/Orgotel-Am.jpg",
    mrp : 84,
  },

  {
    id: 3,
    Productname: "Orglim-MV3",
    Content: "Glimepiride 2mg + Metformin Hydrochloride 500mg",
    path: "/orgomed products/Orglim-MV3.jpg",
    mrp : 270,
  },

  
  {
    id: 4,
    Productname: "Orglim-MV2",
    Content: "Glimepiride 1mg + Metformin Hydrochloride 500mg",
    path: "/orgomed products/Orglim-MV2.jpg",
    mrp : 140,
  },

  {
    id:5,
    Productname: "Orglim-M2",
    Content: "Glimepride & Metformin Hydrocloride.",
    path:"/orgomed products/Orglim-m2.jpg",
    mrp: 92,
  },

  {
    id: 6,
    Productname: "Orglim-M1",
    Content: "Glimepiride 1mg + Metformin Hydrochloride 500mg Tablet",
    path: "/orgomed products/orglimm1.jpg",
    mrp : 75,
  },

  // Orgodapa-M10- Missing

  {
    id: 8,
    Productname: "Orgodapa-10",
    Content: "Dapagliflozin 10mg Tablet",
    path: "/orgomed products/Orgodapa-10.jpg",
    mrp : 130,
  },

  {
    id: 9,
    Productname: "Orgilda-M 500",
    Content: "Telmisartan 40mg + Amlodipine Besilate 5mg Tablet",
    path: "/orgomed products/Orgilda m 500.jpg",
    mrp : 140,
  },

  {
    id: 10,
    Productname: "Orgilda-50",
    Content: "Vildagliptin 50 mg Tablet",
    path: "/orgomed products/orgilda50.jpg",
    mrp : 120,
  },

  {
    id:11,
    Productname: "Bitovita",
    Content: "Lycopene, Lutein, Grape Seed Extract, Vitamins, Folic Acid, Zinc & Selenium Softgel Cap",
    path: "/orgomed products/Bitovita.jpg",
    mrp : 150,
  },
  {
    id:12,
    Productname: "Bitovita-fort",
    Content:"Grape Seed, Ginseng, Ginkgo Biloba, Green Tea Extract with Citrus Bioflavonoids.",
    path: "/orgomed products/Bitovitaf.jpg",
    mrp : 299,
  },



  {
    id: 13,
    Productname: "Orgopent-40 DSR",
    Content: "Pantoprazole Gastro Resistant 40mg + Domperidone 30mg",
    path: "/orgomed products/Orgopent-40 dsr.jpg",
    mrp : 130,
  },

  {
    id: 14,
    Productname: "Orgopent-40",
    Content: "Pantoprazole Gastro Resistant 40mg Tablet",
    path: "/orgomed products/Orgopent-40.jpg",
    mrp : 85,
  },

  // Orgopent-SP missing


  // Orgomont - L Tab Missing

  {
    id: 17,
    Productname: "Orgodef - 6",
    Content: "Deflazacort Tablets",
    path: "/orgomed products/Orgodef-6.jpg",
    mrp : 155,
  },

  //  Orcough-DX syp Missing

  // Orcough-ls Missing

  // Orcold-P Missing

  // OrgoCEF 200 Missing
  {
    id: 22,
    Productname: "HIMIN",
    Content: "Heamatinic Syrup of Iron, Folc Acid and Cyanocobalamin Tonic",
    path: "/orgomed products/HIMIN.png",
    Qun:"200 ml",
    mrp : 180,
  },




 
  // {
  //   id: 11,
  //   Productname: "Orglim-M2",
  //   Content: "Glimepiride 2mg + Metformin Hydrochloride 500mg",
  //   path: "/orgomed products/Orglim-M2.jpg",
  //   mrp : 55, 
  // },

 

  {
    id: 100,
    Productname: "Orgodapa-M 500",
    Content: "Dapagliflozin 10mg + Metformin Hydrochloride 500mg",
    path: "/orgomed products/Orgodapa-M 500.jpg",
    mrp : 55,
  },


 
  // {
  //   id: 12,
  //   Productname: "Orgoroz-AS 20",
  //   Content: "Rosuvastatin Calcium 20mg + Aspirin 75mg Capsule",
  //   path: "/orgomed products/Orgoroz AS 20.jpg",
  //   mrp : 55,
  // },

  
];
export default Products;