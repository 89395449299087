import React from 'react'
import Productimg from './Productimg'

const Mainpro = () => {
  return (
    <div>
    <div class="producthome">
    <div class="textpro font-bold lg:text-7xl md:text-4xl sm:text-4xl bg-teal-500 p-4 rounded-md text-white">
        <h2>Our Products</h2>

    </div>
</div>

<Productimg/>
    </div>
  )
}

export default Mainpro