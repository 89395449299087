import React from 'react'
import Formsection from './Formsection'
const Maincont = () => {
  return (
    <div>
    <div className='contacthome'>
    <div className='textpro font-bold lg:text-7xl md:text-4xl sm:text-4xl bg-teal-500 p-4 rounded-md text-white'>
        <h2>Contact Us</h2>

    </div>
</div>

<Formsection/>



    </div>
  )
}

export default Maincont